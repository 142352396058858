import { FC } from "react";

import { TodayOutlined } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";

import { formatDateWithTz } from "@fllite/ui/utils";

import { DATE_FORMAT } from "./FlightCard";

interface DateBoxProps {
  date: string;
  timezone: string;
  title: string;
  isBooked?: boolean;
  fixed?: boolean;
  withIcon?: boolean;
  alignRight?: boolean;
}

export const DateBox: FC<DateBoxProps> = ({
  date,
  timezone,
  title,
  isBooked,
  fixed,
  withIcon,
  alignRight,
}) => (
  <Box
    width={fixed ? "120px" : "auto"}
    {...(alignRight
      ? {
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
        }
      : {})}
  >
    <Typography
      color="#97989A"
      fontWeight={500}
      fontSize={12}
      letterSpacing="2px"
    >
      {title}
    </Typography>
    <Stack direction="row" spacing={1} mt="4px">
      {withIcon && (
        <TodayOutlined
          color="success"
          sx={{ marginTop: "2px", fontSize: "16px" }}
        />
      )}
      <Box {...(alignRight ? { textAlign: "right" } : {})}>
        <Typography
          color={isBooked ? "white" : "#263238"}
          fontWeight={600}
          fontSize={14}
        >
          {formatDateWithTz(date, timezone, DATE_FORMAT)}
        </Typography>
        <Typography color={isBooked ? "white" : "#263238"} fontSize={12}>
          {formatDateWithTz(date, timezone, "hh:mm A z")}
        </Typography>
      </Box>
    </Stack>
  </Box>
);
